/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'

type IconType = {
  icon: string
  className?: string
  fontClassName?: string
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent) => void
}

const IconFont: React.FC<IconType> = ({ icon, fontClassName, className, style, onClick }) => {
  return (
    <span className={`anticon ${fontClassName ?? ''}`}>
      <i className={`iconfont ${icon} ${className ?? ''}`} style={style as any} onClick={onClick} />
    </span>
  )
}

export default IconFont
